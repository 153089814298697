import React from 'react';
import { graphql } from 'gatsby';

import Layout from '../components/layout';
import Seo from '../components/seo';

import Hero from '../components/rows/hero/index';
import Banner from '../images/hero-images/ocean-ereferral-network-hero.jpg';
import TwoCol from '../components/rows/twocol/index';

import Follow from '../components/rows/cta/follow';
import Cta from '../components/rows/cta/index';

export default function OceanNetwork({ data }) {
  const oceanCol = data.strapiOceanEReferralNetwork.oceanTwocol[0];

  return (
    <Layout>
      <Seo title="Ocean Network" />
      <Hero title="Ocean Network" banner={Banner} />
      <TwoCol
        title={oceanCol.title}
        description={oceanCol.description}
        image={oceanCol.image.localFile.publicURL}
        link={oceanCol.button_title}
        href={oceanCol.button_link}
      />
      <Cta />
      <div className="mt-56">
        <Follow />
      </div>
    </Layout>
  );
}

export const query = graphql`
  query OceanNetwork {
    strapiOceanEReferralNetwork {
      oceanTwocol {
        id
        title
        description
        button_link
        button_title
        image {
          localFile {
            publicURL
          }
        }
      }
    }
  }
`;
